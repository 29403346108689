import request from "@/utils/request";

export function getGameList(params) {
  return request({
    url: "/v2/game_list/",
    method: "get",
    params
  });
}

export function getGameData(params) {
  return request({
    url: "/v2/game_data/",
    method: "get",
    params
  });
}

export function getGameAdvanceData(params) {
  return request({
    url: "/v2/game_advance_data/",
    method: "get",
    params
  });
}

export function getTeamGameData(params) {
  return request({
    url: "/team_game_data/",
    method: "get",
    params
  });
}

export function getPlayerGameData(params) {
  return request({
    url: "/player_game_data/",
    method: "get",
    params
  });
}
