<template lang="pug">
v-col.players_ranking_card_slider.position-relative.mt-6.mt-md-0(
  cols="12",
  md="6"
)
  v-sheet.position-relative.border.w-100(style="margin-top: 35px", tile)
    v-tabs-items(v-model="rankingTab")
      v-tab-item(
        v-for="(offensive, index) in playersRankingList",
        :key="index"
      )
        .item_container.d-flex
          .pic_container.d-flex.align-center.pa-4.temp_logo_hidden
            v-img.img_circle(:src="offensive.rankings[index].info.photo.image")
          ul.ranking_list.pa-1.d-flex.flex-column.w-100
            li.d-flex.align-center.flex-grow-1.rounded-sm.px-5(
              v-for="(ranking, rankingIndex) in offensive.rankings",
              :key="rankingIndex",
              @click="playerClick(ranking)"
            )
              .ranking.text-center.flex-grow-5(
                :class="[ranking.ranking.ranking < 4 ? 'text_secondary' : 'text_light_gray']"
              ) {{ getRankText(ranking.ranking.ranking) }}
              span.number.flex-grow-4 {{ ranking.number }}
              span.player.flex-grow-4 {{ ranking.info.name }}
              span.score.flex-grow-1.text-right {{ ranking.ranking.value.toFixed(2) }}
  .tabs.position-absolute.top-0
    v-tabs.sheet-s-border.position-relative(
      v-model="rankingTab",
      ripple="false"
    )
      v-tabs-slider(hidden="true")
      v-tab(
        v-for="(offensive, index) in playersRankingList",
        :key="index",
        :ripple="false"
      ) {{ offensive.title }}
  .card.position-absolute
    span.text_value {{ rankingTitles.titleEng }}
    span.font-weight-bold.ml-2(style="color: #ababab") {{ rankingTitles.title }}
</template>

<script>
export default {
  name: "PlayersRankingCardSlider",
  props: {
    playersRankingList: Array,
    rankingTitles: Object,
  },
  data() {
    return {
      rankingTab: null,
    };
  },
  methods: {
    playerClick(player) {
      this.$emit("playerClick", player);
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-sheet {
  li {
    .ranking {
      // color: $lightGray;
      flex-basis: 30px;
    }

    &:first-child {
      .ranking {
        // color: white;
      }
    }
  }
}

.img_circle {
  @include circle(128px);
}

.ranking_list {
  li {
    height: 36px;
    border-bottom: #d2d2d2 1px solid;
    cursor: pointer;

    &:first-child {
      color: white;
      background-color: #002442;
      border-bottom: none;

      .score {
        font-family: Helvetica;
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .ranking {
      flex-grow: 0;
    }

    .number {
      flex-grow: 3;
      text-align: center;
    }

    .player {
      flex-grow: 5;
    }

    .score {
      flex-grow: 4;
    }
  }
}

.card {
  right: 0;
  top: 0;

  .text_value {
    font-style: italic;
    font-weight: bold;
    font-family: Helvetica;
    font-size: 20px;
  }
}

@media (max-width: 1263px) {
  .card {
    text-align: right;
    right: 12px;
    top: -10px;

    span.font-weight-bold {
      display: block;
    }
  }
}

@media (max-width: 599px) {
  .ranking_list {
    li {
      height: 38px;

      .number,
      .ranking,
      .score,
      .player {
        font-size: 14px;
      }

      &:first-child {
        .score {
          font-size: 20px;
        }
      }
    }
  }

  .card {
    .text_value,
    span.font-weight-bold {
      font-size: 3.3vw !important;
    }

    span {
      display: block;
      font-size: 3.3vw;
    }
  }
}
</style>
