<template lang="pug">
.league
  .top-dropdown
    v-container
      v-row(justify="center")
        v-col.select_label
          span.text-body-2 選擇賽季：
        v-col.clear(cols="auto", style="padding: 0")
          select.selector(name="seasonInquiry", v-model="seasonInquiryType")
            option(:value="index", v-for="(item, index) in leagueData.seasons") {{ item.name }}
  v-container
    v-row.mt-16 
      v-col(cols="12")
        h5.normal_title_h5 賽事列表
        v-divider.d-block
    v-row.table_row
      v-col.table_col.table.position-relative(cols="12")
        table.text-center
          thead
            th.bottom_head(
              :class="{ bdr: index != gameEventHeadTitleList.length - 1 }",
              v-for="(title, index) in gameEventHeadTitleList",
              :key="index"
            ) {{ title }}
          tbody
            tr.hover(
              v-for="(game, index) in gameList",
              :key="index",
              style="cursor: pointer",
              @click="gameClick(game)"
            )
              td.bdl.bdr {{ game.category }}
              td.bdr {{ game.name }}
              td.bdr {{ formatDate(game.date) }}
              td.bdr
                .team_container.d-flex.align-center.justify-center
                  img.temp_logo_hidden(
                    width="30px",
                    :src="game.home_team_logo && game.home_team_logo.image"
                  )
                  span.ml-1 {{ game.home_team_name }}
                  span.mx-2 vs
                  img.temp_logo_hidden(
                    width="30px",
                    :src="game.away_team_logo && game.away_team_logo.image"
                  )
                  span.ml-1 {{ game.away_team_name }}
              td(v-if="game.home_team_pts == 0 && game.away_team_pts == 0")
                span.text_light_gray -
              td(v-else)
                span(
                  :class="[game.home_team_pts > game.away_team_pts ? 'text_secondary' : 'text_light_gray']"
                ) {{ game.home_team_pts }}
                span :
                span(
                  :class="[game.home_team_pts < game.away_team_pts ? 'text_secondary' : 'text_light_gray']"
                ) {{ game.away_team_pts }}
    v-row
      v-col
        v-pagination(
          v-model="currentPage",
          :length="totalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle
        )
  v-container
    v-row.mt-5
      v-col(cols="12")
        h5.normal_title_h5 球隊排行
        v-divider.d-block
    v-row.table_row(ref="teamRanking")
      v-col#sticky-nav.table_col.table(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column(
                style="width: 50px; min-width: 50px",
                :title="'排名'",
                :offset="0"
              )
              sticky-column.bdr2.text-center(
                style="width: 120px; min-width: 120px",
                :title="'球隊'",
                :offset="50"
              )
              th.bdr 場次
              th.bdr 勝
              th.bdr 敗
              th.bdr 勝率
              th.bdr 勝差
              th.bdr 連勝/連敗
          tbody
            tr.hover(
              v-for="(team, index) in teamRankingList",
              :key="index",
              style="cursor: pointer",
              @click="teamClick(team)"
            )
              sticky-column.bdl(
                style="width: 50px; min-width: 50px",
                :offset="0"
              )
                .ranking.text-center(
                  :class="[index < 3 ? 'text_secondary' : 'lightGray']"
                ) {{ getRankText(index + 1) }}
              sticky-column.bdr2(
                style="width: 120px; min-width: 120px",
                :offset="50"
              )
                .team_container.d-flex.justify-center
                  //- img.temp_logo_hidden(
                  //-   width="30px",
                  //-   v-if="team.photo",
                  //-   :src="team.photo.image"
                  //- )
                  span.ml-1 {{ team.info.name }}
              td.bdr {{ team.info.record_matches }}
              td.bdr {{ team.info.wins }}
              td.bdr {{ team.info.losses }}
              td.bdr {{ (team.info.win_rate * 100).toFixed(1) }}%
              td.bdr {{ team.info.game_behind }}
              td {{ getStreakText(team.info.winning_streak) }}
    v-row.mt-16
      v-col(cols="12")
        h5.normal_title_h5 球員排行
        v-divider.d-block
    v-row.mt-md-5
      players-ranking-card-slider(
        v-for="(n, index) in rankingTitles.length",
        :key="index",
        :playersRankingList="index == 0 ? playerOffensiveList : playerDefensiveList",
        :rankingTitles="rankingTitles[index]",
        @playerClick="playerClick"
      )
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import PlayersRankingCardSlider from "@/components/league/PlayersRankingCardSlider";
import { getLeagueList } from "@/api/league";
import { getTeamSeasonData } from "@/api/team";
import { getGameList } from "@/api/game";
import { getPlayerRankingData } from "@/api/player";
import moment from "moment";

export default {
  name: "LeagueLeagueOverview",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    PlayersRankingCardSlider,
    StickyColumn,
  },
  data() {
    return {
      league: this.$route.params.league,
      leagueData: {},
      seasonInquiryType: 0,
      teamRankingList: [],
      gameList: [],
      playerRankingData: {},
      currentPage: 1,
      totalPages: 1,
      perPage: 10,
      gameEventHeadTitleList: ["賽別", "場次", "日期", "隊伍", "比分"],
      rankingTitles: [
        {
          title: "進攻排名",
          titleEng: "Offensive Leaders",
        },
        {
          title: "防守排名",
          titleEng: "Defensive Leaders",
        },
      ],
      playerOffensiveList: [],
      playerDefensiveList: [],

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    seasonInquiryType() {
      this.teamRankingList = [];
      this.gameList = [];
      this.playerRankingData = {};
      this.currentPage = 1;
      this.totalPages = 1;
      this.playerOffensiveList = [];
      this.playerDefensiveList = [];

      this.initData();
    },
    currentPage() {
      this.getGameList();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getLeagueList();
      await this.getTeamSeasonData();
      await this.getGameList();
      await this.getPlayerRankingData();
    },
    async getLeagueList() {
      await getLeagueList().then((response) => {
        this.leagueData = response.data;

        for (let index in response.data) {
          let temp = response.data[index];
          if (temp.name == this.league) {
            this.leagueData = Object.assign({}, temp);
            break;
          }
        }
      });
    },
    async getTeamSeasonData() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        part: "info",
      };
      await getTeamSeasonData(temp).then((response) => {
        this.teamRankingList = response.data.sort(function (a, b) {
          return b.info.win_rate - a.info.win_rate;
        });
      });
    },
    async getGameList() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        page_size: this.perPage,
        page: this.currentPage,
      };
      await getGameList(temp).then((response) => {
        this.gameList = response.data.results;
        this.totalPages = Math.ceil(response.data.count / this.perPage);
      });
    },
    async getPlayerRankingData() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        part: "info,ranking",
        ranking: 10,
      };
      await getPlayerRankingData(temp).then((response) => {
        this.playerRankingData = response.data;

        let score = {
          type: "score",
          title: "得分",
          rankings: this.playerRankingData.avg_pts,
        };
        this.playerOffensiveList.push(score);

        let threePoints = {
          type: "threePointScore",
          title: "三分得分",
          rankings: this.playerRankingData.avg_three_pts,
        };
        this.playerOffensiveList.push(threePoints);

        let assist = {
          type: "assist",
          title: "助攻",
          rankings: this.playerRankingData.avg_ast,
        };
        this.playerOffensiveList.push(assist);

        let rebound = {
          type: "rebound",
          title: "籃板",
          rankings: this.playerRankingData.avg_reb,
        };
        this.playerDefensiveList.push(rebound);

        let steal = {
          type: "steal",
          title: "抄截",
          rankings: this.playerRankingData.avg_stl,
        };
        this.playerDefensiveList.push(steal);

        let block = {
          type: "block",
          title: "阻攻",
          rankings: this.playerRankingData.avg_blk,
        };
        this.playerDefensiveList.push(block);
      });
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    getStreakText(count) {
      if (count > 1) {
        return count + "連勝";
      } else if (count < -1) {
        return 0 - count + "連敗";
      } else {
        return "-";
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    teamClick(team) {
      this.$router.push({
        name: "LeagueTeam",
        params: {
          league: this.leagueData.id,
          season: this.leagueData.seasons[this.seasonInquiryType].id,
          id: team.info.id,
        },
      });
    },
    gameClick(game) {
      this.$router.push({
        name: "LeagueGame",
        params: {
          league: this.leagueData.id,
          season: this.leagueData.seasons[this.seasonInquiryType].id,
          id: game.id,
        },
      });
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          league: this.leagueData.id,
          season: this.leagueData.seasons[this.seasonInquiryType].id,
          id: player.info.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
</style>
