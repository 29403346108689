import request from "@/utils/request";

export function getTeamSeasonList(params) {
  return request({
    url: "/v2/team_season_list/",
    method: "get",
    params,
  });
}

export function getTeamSeasonData(params) {
  return request({
    url: "/v2/team_season_data/",
    method: "get",
    params,
  });
}

export function getTeamGameData(params) {
  return request({
    url: "/team_game_data/",
    method: "get",
    params,
  });
}

export function getTeamRankingData(params) {
  return request({
    url: "/v2/team_ranking_data/",
    method: "get",
    params,
  });
}
